<script>
export default {
    name: "ThankYou",
    data() {
        return {
            nocId: null,
            noc: {},
            nocSlip: "",
            reciept: "",
            showrReceiptDownload: true
        };
    },
    created() {
        this.nocId = this.$route.params?.nocId;

        if(this.$route.name === "dealer.thank") {
            this.showrReceiptDownload = false
        }

        if (this.nocId) {
            this.getData();
        }
    },
    methods: {
        async getData() {
            try {
                const { noc } = await this.$http.getById(
                    "common/noc-detail",
                    this.nocId
                );
                this.noc = noc;
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        async generatePdf() {
            try {
                const file = await this.$http.rawPost(
                    `noc/get-noc-slip/${this.nocId}`
                );
                this.nocSlip = file.file;
                this.$refs.downloadPdf.href = `${process.env.VUE_APP_HOST}/pdf/${this.nocSlip}.pdf`;
                this.$refs.downloadPdf.click();

            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        async generateRecieptPdf() {
            try {
                const file = await this.$http.rawPost(
                    `noc/get-noc-reciept/${this.nocId}`
                );
                this.reciept = file.file;

                this.$refs.downloadRecieptPdf.href = `${process.env.VUE_APP_HOST}/pdf/${this.reciept}.pdf`
                this.$refs.downloadRecieptPdf.click();

            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
    },
    computed: {
        nocFileName() {
            return `${process.env.VUE_APP_HOST}/pdf/${this.nocSlip}.pdf`
        },
        recieptFileName() {
            return `${process.env.VUE_APP_HOST}/pdf/${this.reciept}.pdf`
        },
    }
};
</script>

<template lang="pug">
.flex.flex-col.text-center
    h1.text-5xl.mt-28.mb-8 Thank You!
    p.lead.my-10
        strong Payment Successful!
        |
    .lead.my-10 Please click the button to download NOC.
    hr
    p.lead.mt-4
        button.bg-green-500.px-7.py-4.rounded-lg(
            class="hover:bg-yellow-600",
            @click="generatePdf"
        ) Download Certificate
    a.display-hide(
        download
        target="_blank"
        ref="downloadPdf"
    )
    p.lead.mt-4(v-if="showrReceiptDownload")
        button.bg-green-500.px-7.py-4.rounded-lg(
            class="hover:bg-yellow-600",
            @click="generateRecieptPdf"
        ) Download Reciept
    a.display-hide(
        download
        target="_blank"
        ref="downloadRecieptPdf"
    )
</template>
